import * as React from 'react';
import {useLayoutEffect,useState} from 'react';
import styled from 'styled-components';
import {TextField, Button, Select,FormControl,MenuItem,InputLabel, Card, CardContent} from '@material-ui/core';
import { DownloadReport } from '~api/ReportingApi';
import ReportStore from '~store/ReportStore'
import {Redirect} from '@reach/router';

const Container = styled.div`
    padding-top: 10%;
    width:50%;
    left:50%;
    margin-left: auto;
    margin-right:auto;
`

const GridContainer = styled.div`
    display:grid;
    grid-template-columns: 25%  25% 25% 25%;

`

const ReportTypes = [ 
    "Workout",
    "Acquisition",
    "Partner"
]


const style = {
    marginLeft: '20px'
}


const ReportComponent = (props:any) => {
    const [reportState, setState] = useState(ReportStore.initial);
    
    if(localStorage.getItem('access_token') == undefined || localStorage.getItem('access_token') == null){
            return <Redirect to="/"></Redirect>;
    }

    useLayoutEffect(() => {
        ReportStore.subscribe(setState);
        ReportStore.init();
    },[]);    
    
    const downloadReport = () => {
        ReportStore.downloadReport();
    }
  
    const setReportType = (event:any) => {
        ReportStore.setReportType(event.target.value);
    }
    const setStartDate = (event:any) => {
        ReportStore.setStartDate(event.target.value); 
    }

    const setEndDate = (event:any) => {
        ReportStore.setEndDate(event.target.value); 
    }

    return ( 
        <Container>
            <Card style={{width:'100%'}}>
                <CardContent>

                    <GridContainer> 
                  <FormControl
                      style={style}
                  >
                    <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reportState.reportType}
                      onChange={setReportType}
                    >
                        {
                            ReportTypes.map((value) => <MenuItem value={value}>{value}</MenuItem>))

                        }
                    
                    </Select>
                    </FormControl>
                  <TextField id="date" label="Start Date" type="date"
                    value={reportState.startDate}
                    onChange={setStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={style}
                  />
                  
                { reportState.reportType != 'Partner' && <TextField
                    id="date"
                    label="End Date"
                    type="date"
                    value={reportState.endDate}  
                    onChange={setEndDate}
                    style={style}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                }
                  <Button onClick={downloadReport}> Download </Button>
                    </GridContainer>
                </CardContent> 
            </Card>
        </Container> 


    )

}

export default ReportComponent;
