import * as React from 'react';
import {useState} from 'react';

import {Router} from '@reach/router';
import LoginComponent from '~components/login/LoginComponent';
import ReportComponent from '~components/reports/ReportComponent';
import styled from 'styled-components';
import { LinearProgress, AppBar, Button } from '@material-ui/core';
import GlobalStore from '~store/GlobalStore';
import subject from '~store/GlobalStore';
import Toolbar from '@material-ui/core/Toolbar';
import LoginStore from '~store/LoginStore';



const Container = styled.div`
    
`

const RouteContainer = styled.div`
    margin-left:'auto';
    margin-right:'auto';
`
const NotFound = () => (
  <div>Sorry, nothing here.</div>
)

class App extends React.Component {
    constructor(props:any){
        super(props)
        this.state = {
            showSpinner: false,
            loggedIn: localStorage.getItem("access_token") != undefined
        }
    }

    componentDidMount(){
        subject.subscribe((state:any) => {
            this.setState(state);
        })
    }


    render(){
        const NotFound = () => <p>Sorry, nothing here</p>
        const logout = () => {
            LoginStore.logout();
        } 
        
        return ( 
            <Container>
                { this.state.showSpinner && <LinearProgress /> }
                { this.state.loggedIn && <AppBar position="static">
                    <Toolbar>
                      <Button color="inherit" onClick={logout}>Logout</Button>
                    </Toolbar>
                  </AppBar>
                }
                <RouteContainer> 
                    <Router>
                        <LoginComponent path="/">  </LoginComponent> 
                        <ReportComponent path="/reports">  </ReportComponent> 
                        <NotFound default />
                    </Router>
                </RouteContainer>
            </Container>
        );    

    }
}


export default App

