import {BehaviorSubject, Subject} from 'rxjs';
import {DownloadReport,DownloadWorkoutReport,DownloadAcquisitionReport} from '~api/ReportingApi';
import GlobalStore from './GlobalStore';
let subject = new Subject();


const initial = {
    data:[],
    dateIssued: new Date(),
    reportType: "Workout",
    startDate: new Date().toString(),
    endDate: new Date().toString()
}

let state = initial;

const ReportStore = {
    subscribe: (setState:any) => subject.subscribe(setState),
    init: () => subject.next(state),
    clear: () => {
        state = initial && subject.next(initial);
    },
    setReportType: (type) => {
        state = {
            ...state,
            reportType: type
        };
        subject.next(state);
    },
    setStartDate:(date:string) => {
        state = {
            ...state,
            startDate: date
        };
        subject.next(state)
    },
    setEndDate:(date:string) => {
        state = {
            ...state,
            endDate: date
        };
        subject.next(state)
    },

    downloadReport: () => {
        let downloadReport;
        switch(state.reportType){
            case "Workout": downloadReport = DownloadWorkoutReport(state.startDate,state.endDate);break;
            case "Acquisition": downloadReport = DownloadAcquisitionReport(state.startDate,state.endDate);break;
            case "Partner": downloadReport = DownloadReport(state.startDate);break;
        } 
        GlobalStore.showSpinner(true);
        downloadReport.then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            console.log(response);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${state.reportType}-${new Date().toISOString()}.csv`);
            document.body.appendChild(link);
            link.click();
            GlobalStore.showSpinner(false);
        }).catch(( error) => {
            GlobalStore.showSpinner(false);

        })
        
    },
    initial
};


export default ReportStore
