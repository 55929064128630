
import * as React from 'react';
import { Component } from "react";
import {Button, Card, CardContent} from '@material-ui/core';
import LoginView from './LoginView';
import styled from 'styled-components';
import { navigate,Redirect } from "@reach/router"

const Container = styled.div`
    width:70%;
    position:absolute;
    top:10%;
    left:10%;
    height:50%;
    `


class LoginComponent extends Component{
   
    consructor(){
    }

    render() {
        if(localStorage.getItem('access_token') != undefined){
            return <Redirect to="/reports"></Redirect>;
        }

        return ( 
            <Container>
                        <LoginView> </LoginView>
                
            </Container> 
        )
    }
}

export default LoginComponent;
