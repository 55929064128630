import {BehaviorSubject, Subject} from 'rxjs';
import {GetReportRequest,Login} from '../api/LoginApi';
let subject = new Subject();
import {navigate} from '@reach/router';
import GlobalStore from './GlobalStore';
import jwt_decode from 'jwt-decode'

const initial = {
    username: "",
    password: "",
    errors:[]
}

let state = initial;

const LoginStore = {
    subscribe: (setState:any) => subject.subscribe(setState),
    init: () => subject.next(state),
    clear: () => {
        state = initial;
        subject.next(initial);
    },
    setPassword: (pass:string) => {
        state = {
            ...state,
            password: pass,
        }
        subject.next(state);
    },
    setUsername: (user:string) => {
        state = {
            ...state,
            username: user
        
        }
        subject.next(state);
    },
    login: () => {
        GlobalStore.showSpinner(true);
        Login(state.username,state.password)
            .then((payload) => {
                GlobalStore.showSpinner(false);
                let decoded_token = jwt_decode(payload.data.access_token);
                if(decoded_token.authorities.indexOf("ROLE_DASHBOARD") > -1){
                    localStorage.setItem("access_token", payload.data.access_token);
                    navigate('/reports');
                    GlobalStore.setLogin(true);
                }
                
            }).catch((err) => {
                GlobalStore.showSpinner(false);
                console.log(err);
            });
    },
    logout: () => {
        localStorage.removeItem('access_token')
        GlobalStore.setLogin(false);
        subject.next(state);
        location.reload();
    },
    initial
};


export default LoginStore
