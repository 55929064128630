import * as React from 'react';
import {useState,useLayoutEffect} from 'react';
import {Input, Button, TextField, FormControl, withStyles, Card, CardContent, Typography} from '@material-ui/core';
import styled from 'styled-components';
import LoginStore from '~store/LoginStore';
import { navigate } from "@reach/router"
const Container = styled.div`
    width:50%;
    left:30%;
    top:30%;
    position:absolute;
`

const StyledForm = withStyles({
    root:{
        width: '100%'
    }
})(FormControl);

const logoStyle = {
    backgroundColor:"black",
    padding:'20px',
    borderRadius: '5px',
    marginBottom:'20px',
    textAlign: "center",
    color:"white"
}

const LoginView = () => {
    const [loginState, setState] = useState(LoginStore.initial);

    useLayoutEffect(() => {
        LoginStore.subscribe(setState);
        LoginStore.init();
    },[]);
    return (
        <Container>
            <Card>
                <CardContent>
                    <StyledForm>
                        <div style={logoStyle}>
                              <img src="https://images.fitplanapp.com:443/web/global/logo.png?&amp;tr=h-80"/>
                               <img src="https://images.fitplanapp.com:443/web/global/logo-text.png?&amp;tr=h-80"/>
                        <Typography variant="h4" >Data Dashboard</Typography>
                        </div>


                        <TextField label="Email" value={loginState.username} onChange={e => LoginStore.setUsername(e.target.value) }></TextField>
                        <br></br>
                        <TextField label="Password" value={loginState.password} onChange={e => LoginStore.setPassword(e.target.value)} type="password"> </TextField>
                        <br></br>
                        <Button variant='contained' onClick={LoginStore.login}>Login</Button>

                    </StyledForm>
                </CardContent> 
            </Card>
        </Container>
    )
};

export default LoginView;
