import axios from 'axios';
import { CONFIG } from '~config';


const AuthHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`
    }
}



export const GetReportRequest = (date:Date) => {
    return axios({
        method: "GET",
        url: `${CONFIG.reportingHost}/report/partner`,
        headers: AuthHeaders()

    })
}

export const DownloadReport = (date:string = new Date().toString()) => {
    return axios({
        method: "GET",
        url: `${CONFIG.reportingHost}/report/partner/download?run_date=${date}`,
        responseType: "blob",
        headers: AuthHeaders()
    })
}


export const DownloadWorkoutReport = (startDate:string = new Date().toString(),endDate:string = new Date().toString()) => {
    return axios({
        method: "GET",
        url: `${CONFIG.reportingHost}/report/workout/download?start_date=${startDate}&end_date=${endDate}`,
        responseType: "blob",
        headers: AuthHeaders()
    })
}


export const DownloadAcquisitionReport = (startDate:string = new Date().toString(),endDate:string = new Date().toString()) => {
    

    return axios({
        method: "GET",
        url: `${CONFIG.reportingHost}/report/acquisition/download?start_date=${startDate}&end_date=${endDate}`,
        responseType: "blob",
        headers: AuthHeaders()
    })
}


