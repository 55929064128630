import axios from 'axios';
import {AxiosRequestConfig} from 'axios';
import {CONFIG} from '~config';



export const Login = (username: string, password: string) => {
    var formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);
    formdata.append("client_id", CONFIG.clientId);
    formdata.append("client_secret",CONFIG.clientSecret);
    formdata.append("grant_type", "password");
    let headers = { 
        headers :{
            'Content-type': 'multipart/form-data',
            'Authorization': `Basic ${btoa(`${CONFIG.clientId}:${CONFIG.clientSecret}`)}`
        }
    }
    return axios.post(CONFIG.loginUrl,formdata,headers);
}


