import {BehaviorSubject, Subject} from 'rxjs';
import {DownloadReport,DownloadWorkoutReport,DownloadAcquisitionReport} from '~api/ReportingApi';
let subject = new Subject();


const initial = {
    showSpinner: false,
    loggedIn: localStorage.getItem('access_token')  != undefined 
}

let state = initial;

const GlobalStore = {
    subscribe: (setState:any) => subject.subscribe(setState),
    init: () => subject.next(state),
    clear: () => {
        state = initial;
        subject.next(initial);
    },
    showSpinner:(show:boolean) => {
        state = {
            ...state,
            showSpinner:show 
        };
        subject.next(state);
    },
    setLogin:(loggedIn) => {
        state = {
            ...state,
            loggedIn: loggedIn
        };
        subject.next(state);
    },
    initial
};

export { subject }

export default GlobalStore 
